.background {
  margin: 40px;
  position: relative; }
  .background .bg {
    object-fit: cover;
    min-height: 760px;
    position: relative;
    width: 100vw; }

html,
body {
  font-family: 'Barlow', sans-serif !important;
  overflow-x: hidden !important; }

.homeAbout {
  margin-top: 100px;
  margin-bottom: 80px; }
  .homeAbout img {
    object-fit: contain; }
  .homeAbout h6 {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 86px; }
    .homeAbout h6::before {
      content: "";
      position: relative;
      display: inline-flex;
      background: #67A8DA;
      height: 2px;
      width: 50px;
      top: -6px;
      margin-right: 20px; }
  .homeAbout h1 {
    color: #082952;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 22px; }
  .homeAbout p {
    font-size: 20px;
    line-height: 30px;
    color: #082952;
    margin-bottom: 0px; }
  .homeAbout h1 {
    font-size: 44px;
    padding-right: 100px;
    line-height: 52px; }

.productionHome {
  margin-left: 40px;
  margin-right: 40px;
  background: #EDEFF2;
  padding-top: 70px;
  padding-bottom: 70px; }
  .productionHome h6 {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 86px; }
    .productionHome h6::before {
      content: "";
      position: relative;
      display: inline-flex;
      background: #67A8DA;
      height: 2px;
      width: 50px;
      top: -6px;
      margin-right: 20px; }
  .productionHome h1 {
    color: #082952;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 22px; }
  .productionHome h6 {
    margin-top: 0;
    margin-bottom: 0; }
  .productionHome h1 {
    font-size: 64px;
    line-height: 77px;
    padding: 0;
    padding-bottom: 30px; }
  .productionHome .box {
    margin-top: 20px;
    background: white;
    text-align: center;
    padding: 20px;
    min-height: 130px; }
    .productionHome .box h5 {
      color: #082952;
      font-size: 22px;
      line-height: 26px;
      font-weight: 600; }
    .productionHome .box p {
      margin-bottom: 0px;
      color: #67A8DA;
      font-size: 18px;
      line-height: 28px; }
  .productionHome img {
    width: 100%; }
  .productionHome .nav-link {
    padding: 0px; }

.presentationWrapper {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  position: relative; }
  .presentationWrapper .col-xl-7,
  .presentationWrapper .col-xl-5,
  .presentationWrapper .col-lg-7,
  .presentationWrapper .col-lg-5 {
    padding: 0; }
  .presentationWrapper .container-fluid {
    padding: 0; }
  .presentationWrapper .presentation2 {
    background: #67A8DA;
    height: 700px;
    width: 100%;
    margin-left: -17px;
    width: 100%; }
  .presentationWrapper .bg {
    margin-left: 15px;
    width: 100%;
    max-height: 700px;
    object-fit: cover;
    height: 700px; }
  .presentationWrapper .presentationContent {
    position: absolute;
    width: 100%;
    top: 120px; }
    .presentationWrapper .presentationContent h6 {
      color: #67A8DA;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      margin-top: 86px; }
      .presentationWrapper .presentationContent h6::before {
        content: "";
        position: relative;
        display: inline-flex;
        background: #67A8DA;
        height: 2px;
        width: 50px;
        top: -6px;
        margin-right: 20px; }
    .presentationWrapper .presentationContent h1 {
      color: #082952;
      font-size: 58px;
      line-height: 70px;
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 22px; }
    .presentationWrapper .presentationContent .btn-mehrinfo {
      font-size: 20px;
      line-height: 24px;
      color: #082952;
      background: #B0D3EE;
      border: 0;
      border-radius: 0;
      font-weight: 600;
      padding: 23px 36px;
      margin-top: 30px; }
      .presentationWrapper .presentationContent .btn-mehrinfo img {
        position: relative;
        margin-left: 35px;
        top: -2px; }
    .presentationWrapper .presentationContent h6 {
      color: white;
      margin: 0; }
      .presentationWrapper .presentationContent h6::before {
        background: white; }
    .presentationWrapper .presentationContent h1 {
      color: white;
      padding-top: 0; }
    .presentationWrapper .presentationContent p {
      color: white;
      font-size: 26px;
      line-height: 30px; }
    .presentationWrapper .presentationContent iframe {
      height: 460px; }
    .presentationWrapper .presentationContent .btnPlay {
      background: white;
      border: 0;
      height: 140px;
      width: 140px;
      border-radius: 100%;
      position: absolute;
      left: 220px;
      top: 163px;
      pointer-events: none; }
  .presentationWrapper .presentationText {
    margin-left: 40px; }

.partner {
  background: #EDEFF2;
  margin-top: 40px;
  padding-top: 64px;
  padding-bottom: 64px;
  margin-left: 40px;
  margin-right: 40px; }
  .partner .first {
    padding-bottom: 30px; }
    .partner .first::after {
      width: 100%;
      height: 1px;
      background: red;
      content: ""; }
  .partner .secound {
    margin-top: 10px;
    padding-bottom: 33px; }
  .partner .third {
    margin-top: 10px;
    padding-bottom: 27px; }
  .partner .partnerText {
    margin-left: 30px; }
    .partner .partnerText h6 {
      color: #67A8DA;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      margin-top: 86px; }
      .partner .partnerText h6::before {
        content: "";
        position: relative;
        display: inline-flex;
        background: #67A8DA;
        height: 2px;
        width: 50px;
        top: -6px;
        margin-right: 20px; }
    .partner .partnerText h1 {
      color: #082952;
      font-size: 58px;
      line-height: 70px;
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 22px; }
    .partner .partnerText h6 {
      margin-top: 0px; }
    .partner .partnerText h1 {
      font-size: 64px;
      padding: 0; }
      .partner .partnerText h1:after {
        content: '';
        height: 100%;
        width: 30px;
        position: absolute;
        background: #EDEFF2;
        right: -50px;
        top: 4px; }
    .partner .partnerText p {
      color: #082952;
      line-height: 30px;
      font-size: 22px; }
  .partner .partnerWrapper {
    border: 20px solid #67A8DA;
    padding: 42px;
    padding-bottom: 60px; }

.homeGallery {
  background: #EDEFF2;
  padding-top: 70px;
  padding-bottom: 50px;
  margin-left: 40px;
  margin-right: 40px; }
  .homeGallery h6 {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 86px; }
    .homeGallery h6::before {
      content: "";
      position: relative;
      display: inline-flex;
      background: #67A8DA;
      height: 2px;
      width: 50px;
      top: -6px;
      margin-right: 20px; }
  .homeGallery h1 {
    color: #082952;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 22px; }
  .homeGallery .btn-mehrinfo {
    background: #082952;
    font-size: 20px;
    line-height: 24px;
    border-radius: 0;
    padding: 17px 23px;
    font-weight: 600; }
    .homeGallery .btn-mehrinfo img {
      margin-left: 20px;
      top: -2px;
      position: relative; }
  .homeGallery h1 {
    font-size: 64px;
    padding: 0; }
  .homeGallery h6 {
    margin-left: 3px;
    margin: 0; }
  .homeGallery .btn-mehrinfo {
    margin-top: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 17px 52px; }
    .homeGallery .btn-mehrinfo img {
      margin-left: 32px; }
  .homeGallery .gallery-wrapper .pic {
    margin-top: 30px; }

.lightbox {
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 70px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

@media screen and (max-width: 360px) and (min-width: 320px) {
  .homeAbout {
    margin-top: -80px !important; }
    .homeAbout img {
      margin-bottom: -80px !important; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .homeAbout {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -60px;
    margin-bottom: 30px; }
    .homeAbout h6 {
      margin-top: 20px; }
    .homeAbout h1 {
      font-size: 32px;
      line-height: 36px;
      padding-top: 22px;
      padding-bottom: 22px; }
    .homeAbout img {
      padding: 3px !important;
      margin-bottom: -50px; }
  .btnPlay img {
    margin-left: -8px; }
  .productionHome h6 {
    margin-top: 40px; }
  .productionHome h1 {
    font-size: 32px;
    line-height: 36px;
    padding-top: 22px;
    padding-bottom: 22px; }
  .lightbox .carousel img {
    height: 100vh !important; }
  .lightbox .fa-times {
    right: 30px !important; }
  .lightbox img {
    margin-top: 0px; }
  .homeGallery {
    margin: 10px;
    padding-top: 40px; }
    .homeGallery .btn-mehrinfo {
      font-size: 15px;
      padding: 17px 42px; }
      .homeGallery .btn-mehrinfo img {
        margin-left: 10px; }
    .homeGallery h1 {
      font-size: 48px; }
  .partner {
    margin-left: 0px;
    margin-right: 0px; }
    .partner .partnerWrapper {
      padding: 20px 10px; }
    .partner .partnerText {
      padding-top: 10px;
      margin-left: 10px; }
      .partner .partnerText h1 {
        font-size: 48px;
        padding-top: 10px;
        line-height: 56px; }
        .partner .partnerText h1::after {
          right: -20px; }
    .partner img {
      padding: 10px; }
  .presentationWrapper {
    margin-left: 10px;
    margin-right: 10px; }
    .presentationWrapper .container-fluid {
      padding: 15px !important; }
    .presentationWrapper .bg {
      margin: 0;
      height: 480px; }
    .presentationWrapper .presentationContent .btnPlay {
      width: 100px !important;
      height: 100px !important;
      top: 31%;
      right: 35%;
      left: unset; }
    .presentationWrapper .presentationContent iframe {
      height: 307px; }
    .presentationWrapper .presentationContent .btn-mehrinfo {
      padding: 23px 14px; }
      .presentationWrapper .presentationContent .btn-mehrinfo img {
        margin-left: 10px; }
    .presentationWrapper .presentation2 {
      margin-left: 0;
      height: 540px; }
    .presentationWrapper .presentationText {
      margin: 0;
      padding: 10px;
      margin-top: 150px; }
      .presentationWrapper .presentationText h1 {
        font-size: 48px; }
  .productionHome {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 40px;
    padding-bottom: 40px; }
    .productionHome h6 {
      margin-top: 0px; }
    .productionHome .box {
      margin-bottom: 20px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .homeAbout {
    margin-top: 60px;
    margin-bottom: 40px; }
    .homeAbout img {
      width: 100%; }
  .homeGallery {
    margin: 20px; }
  .partner {
    margin: 20px; }
    .partner .partnerWrapper img {
      width: 100%; }
    .partner .partnerText {
      margin-left: 0; }
  .productionHome {
    margin-left: 20px;
    margin-right: 20px; }
    .productionHome .box {
      margin-bottom: 20px; }
  .presentationWrapper {
    margin-left: 20px;
    margin-right: 20px; }
    .presentationWrapper .container-fluid {
      padding: 15px; } }

@media screen and (max-width: 990px) and (min-width: 768px) {
  .presentationWrapper .bg {
    margin: 0;
    height: 480px; }
  .presentationWrapper .presentationContent {
    top: 28px; }
    .presentationWrapper .presentationContent .videoPicture {
      display: block;
      margin: auto; }
    .presentationWrapper .presentationContent .btnPlay {
      width: 100px !important;
      height: 100px !important;
      top: 38%;
      right: 42%;
      left: unset; }
    .presentationWrapper .presentationContent .btn-mehrinfo {
      padding: 23px 19px; }
  .presentationWrapper .presentation2 {
    margin-left: 0;
    height: 540px; }
  .presentationWrapper .presentationText {
    padding: 10px;
    margin: 0;
    margin-top: 110px; }
    .presentationWrapper .presentationText h1 {
      font-size: 48px; } }

.headerWrapper .header {
  margin-bottom: unset; }

.header {
  background: #EDEFF2;
  margin: 40px;
  margin-bottom: -20px;
  padding-top: 16px; }
  .header .fa-globe {
    color: #082952;
    transform: scale(1.4); }
  .header .nav-link {
    cursor: pointer; }
  .header .container {
    padding: 0 !important; }
  .header .dropdown-menu {
    margin-top: 10px;
    background: #082952;
    border-radius: 0;
    padding-top: 15px;
    padding-bottom: 13px; }
  .header .dropdown-item {
    color: #EDEFF2;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding-top: 9px;
    padding-bottom: 9px;
    justify-content: space-between;
    display: flex;
    padding-right: 38px;
    padding-left: 20px; }
  .header .dropdown-toggle::after {
    display: none; }
  .header .btn-menu {
    display: none; }
  .header .btn-language {
    display: none; }
  .header .navbar-nav .nav-item {
    list-style-type: none !important;
    padding-right: 112px; }
    .header .navbar-nav .nav-item:last-child {
      padding-right: 0 !important; }
    .header .navbar-nav .nav-item .nav-link {
      color: #082952;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600 !important; }
      .header .navbar-nav .nav-item .nav-link:last-child {
        padding-right: 0; }
  .header .navbar-nav .fa-angle-down {
    color: #67A8DA;
    font-size: 28px;
    position: absolute;
    left: 124px;
    bottom: 3px; }
  .header .navbar-nav .fa-angle-right {
    color: #67A8DA;
    font-size: 22px;
    position: relative;
    left: 18px;
    top: -1px; }
  .header .navbar2 {
    top: 80px;
    right: 60px;
    position: absolute; }
    .header .navbar2 .nav-item {
      list-style-type: none !important; }
      .header .navbar2 .nav-item .nav-link {
        color: #082952;
        font-size: 18px;
        font-weight: 400;
        padding: 0.5rem 0.9rem; }
      .header .navbar2 .nav-item .language::after {
        content: '|';
        position: relative;
        left: 14px; }

.navbar3 {
  top: 80px;
  right: 60px;
  position: absolute; }
  .navbar3 .nav-item {
    list-style-type: none !important; }
    .navbar3 .nav-item .nav-link {
      color: #082952;
      font-size: 18px;
      font-weight: 400;
      padding: 0.5rem 0.9rem; }
    .navbar3 .nav-item .language::after {
      content: '|';
      position: relative;
      left: 14px; }

.headerWrapper .headerBreadcrumb {
  padding: 16px;
  padding-top: 100px; }
  .headerWrapper .headerBreadcrumb h1 {
    font-size: 54px;
    line-height: 65px;
    font-weight: 600;
    color: #082952; }
  .headerWrapper .headerBreadcrumb .nav-item {
    list-style: none; }

.headerBreadcrumb .nav-link:last-child {
  padding-right: 0;
  padding-left: 10px; }

.headerBreadcrumb .nav-item {
  list-style: none; }

.headerBreadcrumb .nav-item::after {
  content: "//";
  font-size: 18px;
  color: #082952;
  line-height: 24px;
  padding-left: 10px;
  display: inline-block; }

.headerBreadcrumb .nav-item:last-child {
  text-decoration: underline; }
  .headerBreadcrumb .nav-item:last-child::after {
    content: ''; }

.headerBreadcrumb .nav-link {
  display: inline;
  font-size: 18px;
  color: #082952;
  line-height: 22px;
  padding: 0px; }

.headerBreadcrumb .breadcrumbUrl {
  display: flex;
  position: relative;
  top: 36px; }

@media screen and (max-width: 768px) and (min-width: 320px) {
  .header {
    margin: 10px; }
    .header .navbar-nav {
      margin-top: 60px; }
      .header .navbar-nav .fa-angle-down {
        color: #67A8DA;
        font-size: 21px;
        position: relative;
        top: 3px;
        left: 7px; }
    .header .navbar2 {
      left: 14px;
      top: 120px;
      margin-top: unset !important;
      right: unset !important;
      display: flex !important; }
    .header .btn-menu {
      display: block;
      transform: scale(1.8);
      background: transparent;
      outline: none;
      border: none; }
    .header .navbar3 {
      display: none !important; }
    .header .btn-language {
      display: none; }
    .header .dropdown-menu {
      background: transparent;
      outline: none;
      border: none;
      margin: 0; }
      .header .dropdown-menu .dropdown-item {
        color: #082952; }
    .header .navbar-brand {
      width: 80%; }
      .header .navbar-brand .isvg svg {
        width: 230px; }
  .headerWrapper .headerBreadcrumb {
    padding-top: 30px; }
    .headerWrapper .headerBreadcrumb h1 {
      font-size: 20px;
      line-height: 24px; }
    .headerWrapper .headerBreadcrumb .breadcrumbUrl {
      display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .header {
    margin: 20px; }
    .header .navbar2 {
      left: 26px;
      top: 130px;
      margin-top: unset !important;
      right: unset !important; }
  .headerWrapper .headerBreadcrumb {
    padding-top: 30px; }
    .headerWrapper .headerBreadcrumb h1 {
      font-size: 20px;
      line-height: 24px; }
    .headerWrapper .headerBreadcrumb .breadcrumbUrl {
      display: none; }
  .btn-language {
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    right: 40px !important;
    top: 140px;
    z-index: 2; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .btn-menu {
    display: block !important;
    transform: scale(1.8);
    background: transparent;
    outline: none;
    border: none; }
  .header .navbar3 {
    display: none !important; } }

@media screen and (max-width: 1024px) and (min-width: 991px) {
  .header .navbar2 {
    display: none !important; } }

@media screen and (max-width: 991px) and (min-width: 769px) {
  .header .navbar2 {
    position: relative;
    top: 0;
    left: 0; } }

@media screen and (max-width: 1600px) and (min-width: 320px) {
  .header #navbarLanguage {
    list-style-type: none;
    position: absolute;
    right: 20px;
    background: #082952;
    z-index: 2; }
    .header #navbarLanguage .nav-link {
      color: white; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .navbar-nav .nav-item {
    padding-right: 102px !important; } }

@media screen and (max-width: 1440px) and (min-width: 1400px) {
  .navbar-nav {
    margin-right: 106px; }
    .navbar-nav .nav-item {
      padding-right: 60px !important; } }

@media screen and (max-width: 1367px) and (min-width: 1299px) {
  .navbar-nav {
    margin-right: 130px; }
    .navbar-nav .nav-item {
      padding-right: 50px !important; } }

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .btn-language {
    top: 88px !important;
    right: 60px !important; }
  #navbarLanguage {
    right: 40px !important; } }

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .navbar-nav {
    margin-right: 60px; }
    .navbar-nav .nav-item {
      padding-right: 40px !important; }
  .header .navbar3 {
    display: none !important; }
  .header .btn-language {
    display: block;
    position: absolute;
    top: 68px;
    right: 40px;
    background: transparent;
    outline: none;
    border: 0; } }

.wrapper {
  width: 59%;
  margin-left: 41%;
  margin-top: 18px; }
  .wrapper .container-fluid {
    padding-left: 0px; }

.headerBox {
  color: white; }
  .headerBox .container {
    background: #67A8DA !important;
    position: absolute;
    top: 280px;
    width: 600px;
    padding-top: 50px !important;
    padding-left: 60px !important;
    padding-bottom: 60px !important;
    margin-left: 16px; }
  .headerBox h6 {
    line-height: 24px;
    font-size: 20px;
    font-weight: 600; }
  .headerBox h1 {
    font-size: 36px;
    line-height: 60px;
    font-weight: 600;
    padding-top: 8px;
    padding-right: 100px; }
  .headerBox .btn-mehrinfo {
    font-size: 20px;
    line-height: 24px;
    color: #082952;
    background: #B0D3EE;
    border: 0;
    border-radius: 0;
    font-weight: 600;
    padding: 23px 36px;
    margin-top: 30px; }
    .headerBox .btn-mehrinfo img {
      position: relative;
      margin-left: 35px;
      top: -2px; }

.verticalText {
  position: absolute;
  left: 10px;
  transform: rotate(-90deg);
  top: 500px;
  color: #082952;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400; }
  .verticalText .fb {
    transform: rotate(90deg); }
  .verticalText .instagram {
    transform: rotate(90deg);
    margin-right: 14px;
    margin-left: 20px; }

.carousel-control-undefined {
  bottom: 115px !important;
  right: unset !important;
  margin-left: unset !important;
  left: -372px !important;
  bottom: 0px !important;
  border-radius: 0% !important;
  width: 60px !important;
  height: 60px !important;
  position: absolute !important;
  background: #082952 !important;
  opacity: 1;
  bottom: 0;
  z-index: 1; }
  .carousel-control-undefined::after {
    display: block !important;
    background: url("./images/desno.png") !important;
    content: '';
    width: 21px !important;
    height: 14px !important;
    margin-top: 23px !important;
    margin-left: 22px;
    z-index: 1066; }
  .carousel-control-undefined:last-child {
    border-radius: 0% !important;
    position: absolute;
    opacity: 1;
    left: -302px !important; }
    .carousel-control-undefined:last-child::after {
      display: block !important;
      background: url("./images/lijevo.png") !important;
      margin-top: 23px !important;
      margin-left: 18px; }

.previewCarousel {
  display: -webkit-box;
  z-index: 2;
  left: 40.7%;
  position: relative;
  bottom: 113px; }
  .previewCarousel img {
    width: 160px;
    outline: 10px solid #67A8DA; }
  .previewCarousel .aktivna::before {
    background: #67A8DA;
    position: absolute;
    bottom: 0px;
    content: '';
    height: 110px;
    width: 155px;
    z-index: 2;
    opacity: 0.5; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .previewCarousel {
    display: none; }
  .wrapper {
    margin-left: 15px;
    width: unset; }
    .wrapper .previewCarousel {
      display: none; }
  .headerBox {
    position: relative;
    bottom: 196px; }
    .headerBox .container {
      top: unset;
      width: 205px;
      padding-top: 15px !important;
      padding-left: 15px !important;
      padding-bottom: 15px !important;
      margin-left: 0px !important; }
    .headerBox h1 {
      font-size: 12px;
      line-height: 16px;
      padding-right: 0px !important; }
    .headerBox h6 {
      font-size: 16px;
      line-height: 16px; }
    .headerBox .btn-mehrinfo {
      font-size: 12px;
      padding: 3px 10px;
      margin-top: 6px; }
      .headerBox .btn-mehrinfo img {
        margin-left: 10px; }
  .verticalText {
    display: none; }
  .carousel-control-undefined {
    right: 66px !important;
    bottom: 0px !important;
    margin-left: unset !important;
    left: unset !important;
    height: 60px !important; }
    .carousel-control-undefined:last-child {
      right: 0px !important;
      left: unset !important; } }

@media screen and (max-width: 360px) and (min-width: 320px) {
  .headerBox {
    bottom: 176px; }
    .headerBox h1 {
      padding-right: 0px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .headerBox {
    display: block !important;
    position: relative; }
    .headerBox .container {
      top: -350px;
      width: 300px;
      padding-top: 30px !important;
      padding-left: 20px !important;
      padding-bottom: 30px !important;
      margin-left: -4px !important;
      left: 0px !important; }
    .headerBox h1 {
      line-height: 26px;
      font-size: 18px;
      padding-right: 0px !important; }
    .headerBox .btn-mehrinfo {
      margin-top: 10px;
      padding: 14px 20px; }
      .headerBox .btn-mehrinfo img {
        margin-left: 16px; }
  .wrapper {
    margin-left: 15px;
    width: unset; }
    .wrapper .previewCarousel {
      display: none; }
  .verticalText {
    display: none; }
  .carousel-control-undefined {
    right: 70px !important;
    bottom: 0px !important;
    margin-left: unset !important;
    left: unset !important;
    height: 60px !important; }
    .carousel-control-undefined:last-child {
      right: 0px !important;
      left: unset !important; }
  .previewCarousel {
    display: none; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .headerBox .container {
    top: 210px;
    padding-top: 40px !important;
    padding-bottom: 50px !important;
    width: 610px; }
  .headerBox h1 {
    font-size: 34px; }
  .verticalText {
    top: 440px;
    left: 0px; }
  .carousel-control-undefined {
    left: -396px !important;
    bottom: 0px !important; }
    .carousel-control-undefined:last-child {
      left: -322px !important; }
  .previewCarousel {
    left: 40.6% !important; } }

@media screen and (max-width: 1580px) and (min-width: 1360px) {
  .headerBox .container {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 30px !important;
    width: 526px;
    top: 210px; }
  .headerBox h1 {
    font-size: 30px;
    line-height: 48px; }
  .headerBox .btn-mehrinfo {
    padding: 16px 20px;
    margin-top: 20px; }
  .carousel-control-undefined {
    left: -420px !important;
    bottom: 0px !important; }
    .carousel-control-undefined:last-child {
      left: -348px !important; }
  .verticalText {
    top: 400px;
    left: 0px; }
  .previewCarousel {
    left: 40.6% !important; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  .headerBox .container {
    width: 460px;
    padding-top: 30px !important;
    padding-left: 40px !important;
    padding-bottom: 30px !important;
    top: 200px !important; }
  .headerBox h1 {
    font-size: 33px;
    line-height: 40px;
    padding-bottom: 8px; }
  .headerBox .btn-mehrinfo {
    margin-top: 0px;
    padding: 14px 36px; }
  .carousel-control-undefined {
    left: -348px !important;
    bottom: -80px !important; }
    .carousel-control-undefined:last-child {
      left: -280px !important; }
  .verticalText {
    display: none; }
  .previewCarousel {
    bottom: 10px;
    left: 39.5%; }
  .wrapper {
    width: 60%;
    margin-left: 40%; } }

.footer {
  margin: 40px;
  background: #082952;
  padding-top: 70px;
  padding-bottom: 70px; }
  .footer p {
    color: #EDEFF2;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px; }
    .footer p span {
      font-weight: 600; }
      .footer p span a {
        color: #EDEFF2; }
  .footer .nav-item {
    list-style-type: none;
    padding-bottom: 10px; }
    .footer .nav-item .nav-link {
      color: #EDEFF2 !important;
      padding: 0;
      font-size: 20px;
      line-height: 24px; }
  .footer h6 {
    color: #EDEFF2 !important;
    margin-bottom: 26px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px; }
  .footer .contactIcons {
    position: relative;
    top: -6px;
    margin-right: 12px; }
  .footer .location {
    position: relative;
    top: -2px;
    margin-right: 8px; }
  .footer .phone {
    margin-left: 3px;
    position: relative;
    top: -2px;
    margin-right: 12px; }
  .footer .footerContact .linkSpacer {
    margin-left: 33px; }
  .footer .footerContact .nav-item {
    padding-bottom: 30px; }
    .footer .footerContact .nav-item .nav-link {
      color: #EDEFF2; }

.copyright {
  padding-top: 26px; }
  .copyright .social {
    margin-left: 10px;
    position: relative;
    bottom: 10px; }
    .copyright .social:first-child {
      margin-left: 0px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .footer {
    padding-top: 40px;
    margin: 10px; }
    .footer .logo {
      padding-bottom: 40px; }
    .footer .nav-link {
      font-size: 14px !important; }
    .footer .contactIcons {
      margin-right: 4px !important; }
    .footer .location {
      margin-right: 3px !important; }
    .footer .phone {
      margin-right: 8px !important; }
    .footer .linkSpacer {
      margin-left: 27px !important; }
    .footer .copyright {
      padding-top: 0px; }
      .footer .copyright p {
        font-size: 14px !important; }
      .footer .copyright .social {
        bottom: -20px !important; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .footer {
    margin: 20px; }
    .footer .copyright {
      padding-top: 0px; }
      .footer .copyright .social {
        bottom: -20px !important; } }

@media screen and (max-width: 1224px) and (min-width: 1023px) {
  .footer {
    margin: 20px; }
    .footer .copyright {
      padding-top: 0px; }
      .footer .copyright .social:first-child {
        margin-left: -30px; } }

.contact {
  padding-bottom: 40px; }
  .contact .btn-mehrinfo {
    background: #082952;
    font-size: 20px;
    line-height: 24px;
    border-radius: 0;
    padding: 17px 23px;
    font-weight: 600; }
    .contact .btn-mehrinfo img {
      margin-left: 20px;
      top: -2px;
      position: relative; }
  .contact h6 {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 86px; }
    .contact h6::before {
      content: "";
      position: relative;
      display: inline-flex;
      background: #67A8DA;
      height: 2px;
      width: 50px;
      top: -6px;
      margin-right: 20px; }
  .contact h1 {
    color: #082952;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 22px; }
  .contact .btn-mehrinfo {
    margin-right: 14px;
    padding: 17px 41px; }
  .contact h6 {
    padding-top: 30px;
    margin: 0; }
  .contact h1 {
    font-size: 64px;
    padding: 0px;
    padding-bottom: 34px; }
  .contact form input::placeholder {
    color: #232628;
    font-size: 14px;
    line-height: 17px;
    margin-top: 14px; }
  .contact form label {
    color: #9F9F9F;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 7px; }
  .contact form input {
    padding: 20px;
    border-radius: 0;
    margin-bottom: 30px;
    height: 50px; }
  .contact form textarea {
    height: 210px;
    border-radius: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contact .btn-mehrinfo {
    margin-top: 40px; }
  .contact h1 {
    font-size: 36px;
    line-height: 58px;
    padding-top: 10px; } }

.googleMap .map {
  height: 600px;
  width: 100%; }

.googleMap .container-fluid {
  padding: 40px !important; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .googleMap {
    margin-top: 30px; }
    .googleMap .map {
      height: 320px !important; }
    .googleMap .container-fluid {
      padding: 10px !important; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .googleMap {
    margin-left: 10px;
    margin-right: 10px; }
    .googleMap .container-fluid {
      padding: 10px !important; } }

.news {
  margin-top: 70px;
  margin-left: 40px;
  margin-right: 40px; }
  .news h6 {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 86px; }
    .news h6::before {
      content: "";
      position: relative;
      display: inline-flex;
      background: #67A8DA;
      height: 2px;
      width: 50px;
      top: -6px;
      margin-right: 20px; }
  .news h1 {
    color: #082952;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 22px; }
  .news .nav-link {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600; }
    .news .nav-link:before {
      display: inline-block !important;
      background: url("./images/desno2.png") !important;
      content: '';
      width: 21px !important;
      height: 14px !important;
      position: relative;
      top: 0;
      right: 18px; }
  .news h1 {
    font-size: 64px;
    padding: 0;
    padding-bottom: 24px; }
  .news h6 {
    margin: 0;
    margin-left: 18px; }
    .news h6:last-child {
      margin-right: 16px; }
      .news h6:last-child:before {
        display: inline-block !important;
        background: url("./images/desno2.png") !important;
        content: '';
        width: 21px !important;
        height: 14px !important;
        position: relative;
        top: 0; }
  .news .box {
    margin-bottom: 60px; }
    .news .box h3 {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      color: #082952;
      padding-right: 50px;
      padding-top: 26px; }
    .news .box p {
      color: #67A8DA;
      font-size: 20px;
      line-height: 24px;
      padding-top: 4px; }
    .news .box .text {
      color: #082952;
      font-size: 22px;
      line-height: 30px;
      padding-right: 40px;
      padding-top: 6px;
      margin-bottom: 22px; }
    .news .box .btn-mehrinfo {
      background: #082952;
      font-size: 20px;
      line-height: 24px;
      border-radius: 0;
      padding: 17px 23px;
      font-weight: 600; }
      .news .box .btn-mehrinfo img {
        margin-left: 20px;
        top: -2px;
        position: relative; }

.boxWrapper .nav-link {
  font-weight: unset;
  padding: 0px; }
  .boxWrapper .nav-link::before {
    content: unset; }

.newsDetail {
  padding-top: 80px; }
  .newsDetail .nav-link {
    padding: 0px; }
  .newsDetail h5 {
    color: #082952;
    font-size: 46px;
    font-weight: 600;
    line-height: 62px;
    margin-bottom: 0; }
  .newsDetail .paragrafBlue {
    color: #67A8DA;
    font-size: 20px;
    line-height: 24px;
    padding: 0; }
  .newsDetail p {
    font-size: 20px;
    color: #082952;
    line-height: 30px; }
  .newsDetail .main {
    width: 95%; }
  .newsDetail .paragraf {
    padding-top: 40px; }
  .newsDetail .newsWrapper .box {
    margin-bottom: 60px; }
    .newsDetail .newsWrapper .box h3 {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      color: #082952;
      padding-right: 50px;
      padding-top: 26px; }
    .newsDetail .newsWrapper .box p {
      color: #67A8DA;
      font-size: 20px;
      line-height: 24px;
      padding-top: 4px; }
    .newsDetail .newsWrapper .box .text {
      color: #082952;
      font-size: 22px;
      line-height: 30px;
      padding-right: 40px;
      padding-top: 6px;
      margin-bottom: 22px; }
    .newsDetail .newsWrapper .box .btn-mehrinfo {
      background: #082952;
      font-size: 20px;
      line-height: 24px;
      border-radius: 0;
      padding: 17px 23px;
      font-weight: 600; }
      .newsDetail .newsWrapper .box .btn-mehrinfo img {
        margin-left: 20px;
        top: -2px;
        position: relative; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .news {
    margin: 10px; }
    .news .box {
      margin-bottom: 20px; }
    .news .btn-mehrinfo {
      margin-bottom: 30px; }
    .news h6:first-child {
      margin-top: 20px; }
    .news h1 {
      font-size: 48px; }
    .news .nav-link {
      margin-left: 10px; }
      .news .nav-link::before {
        right: 8px; }
  .newsDetail {
    padding-top: 30px; }
    .newsDetail h5 {
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 16px; }
    .newsDetail .paragraf {
      padding-top: 30px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .news .btn-mehrinfo {
    margin-bottom: 30px; }
  .news .box {
    margin-bottom: 20px; }
  .newsDetail {
    padding-top: 30px; }
    .newsDetail h5 {
      margin-bottom: 16px;
      font-size: 40px;
      line-height: 56px; }
    .newsDetail .paragraf {
      padding-top: 30px; } }

.kunstoff {
  position: absolute;
  width: 100%;
  top: 111px; }
  .kunstoff h1 {
    color: #FFFFFF;
    font-size: 42px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase; }
    .kunstoff h1::after {
      content: '';
      background: #FFFFFF;
      height: 2px;
      width: 50px;
      position: absolute;
      left: 4px;
      bottom: -20px; }
  .kunstoff h2 {
    color: #FFFFFF;
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 34px; }
  .kunstoff p {
    padding-top: 70px;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF; }
  .kunstoff .textWrapper {
    padding-top: 26px;
    padding-left: 50px; }

.holzfenstern {
  position: absolute;
  width: 100%;
  top: 111px; }
  .holzfenstern h1 {
    color: #FFFFFF;
    font-size: 42px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase; }
    .holzfenstern h1::after {
      content: '';
      background: #FFFFFF;
      height: 2px;
      width: 50px;
      position: absolute;
      left: 4px;
      bottom: -20px; }
  .holzfenstern h2 {
    color: #FFFFFF;
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 34px; }
  .holzfenstern p {
    padding-top: 70px;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF; }
  .holzfenstern .textWrapper {
    padding-top: 26px;
    padding-left: 50px; }
  .holzfenstern .textWrapper h1 {
    color: #082952; }
    .holzfenstern .textWrapper h1::after {
      background: #67A8DA; }
  .holzfenstern .textWrapper p {
    color: #082952;
    line-height: 40px; }

.certificate {
  margin: 40px;
  background: #EDEFF2;
  padding-top: 55px; }
  .certificate .btn-mehrinfo {
    background: #082952;
    font-size: 20px;
    line-height: 24px;
    border-radius: 0;
    padding: 17px 23px;
    font-weight: 600; }
    .certificate .btn-mehrinfo img {
      margin-left: 20px;
      top: -2px;
      position: relative; }
  .certificate .nav-item {
    list-style: none; }
    .certificate .nav-item .nav-link {
      text-decoration: none; }
  .certificate h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    left: 65px;
    padding-bottom: 60px; }
    .certificate h1::before {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      background: #082952;
      left: -65px;
      top: 20px; }
  .certificate h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    color: #082952;
    padding-top: 28px;
    position: relative; }
    .certificate h2::before {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      background: #67A8DA;
      bottom: -8px; }
  .certificate .btn-mehrinfo {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px; }
    .certificate .btn-mehrinfo img {
      margin-left: 30px;
      width: unset; }
  .certificate img {
    width: 100%; }

.certificate2 {
  margin: 40px;
  background: #67A8DA;
  padding-top: 55px; }
  .certificate2 .btn-mehrinfo {
    background: #082952;
    font-size: 20px;
    line-height: 24px;
    border-radius: 0;
    padding: 17px 23px;
    font-weight: 600; }
    .certificate2 .btn-mehrinfo img {
      margin-left: 20px;
      top: -2px;
      position: relative; }
  .certificate2 h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    left: 65px;
    padding-bottom: 60px;
    color: #FFFFFF; }
    .certificate2 h1::before {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      background: #FFFFFF;
      left: -65px;
      top: 20px; }
  .certificate2 h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    color: #FFFFFF;
    padding-top: 28px;
    position: relative; }
    .certificate2 h2::before {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      background: #FFFFFF;
      bottom: -8px; }
  .certificate2 .btn-mehrinfo {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px; }
    .certificate2 .btn-mehrinfo img {
      margin-left: 30px;
      width: unset; }
  .certificate2 img {
    width: 100%; }

.aluminium {
  padding-top: 100px;
  padding-bottom: 100px; }
  .aluminium h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 47px;
    position: relative;
    color: #082952; }
    .aluminium h1::after {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      background: #67A8DA;
      bottom: -12px;
      right: 0; }
  .aluminium p {
    font-size: 20px;
    line-height: 40px;
    color: #082952;
    padding-top: 36px; }
  .aluminium .textWrapper {
    position: relative;
    right: 40px;
    padding-top: 36px;
    text-align: right; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .background {
    margin: 10px; }
    .background .bg {
      min-height: 980px; } }

@media screen and (max-width: 767px) and (min-width: 320px) and (max-width: 767px) and (min-width: 320px) {
  .kunstoff {
    top: 35px; }
    .kunstoff h1 {
      font-size: 32px; }
    .kunstoff p {
      padding-top: 50px;
      font-size: 18px; }
    .kunstoff .textWrapper {
      padding-left: 0px; } }

@media screen and (max-width: 767px) and (min-width: 320px) and (max-width: 1024px) and (min-width: 768px) {
  .kunstoff {
    top: 50px; }
    .kunstoff h1 {
      font-size: 30px; }
    .kunstoff p {
      font-size: 18px;
      padding-top: 32px; }
    .kunstoff .textWrapper {
      padding-top: 0px;
      padding-left: 0px; } }

@media screen and (max-width: 767px) and (min-width: 320px) and (max-width: 767px) and (min-width: 320px) {
  .holzfenstern {
    top: 35px; }
    .holzfenstern h1 {
      font-size: 32px; }
    .holzfenstern p {
      padding-top: 50px;
      font-size: 18px; }
    .holzfenstern .textWrapper {
      padding-left: 0px; } }

@media screen and (max-width: 767px) and (min-width: 320px) and (max-width: 1024px) and (min-width: 768px) {
  .holzfenstern {
    top: 50px; }
    .holzfenstern h1 {
      font-size: 30px; }
    .holzfenstern p {
      font-size: 18px;
      padding-top: 32px; }
    .holzfenstern .textWrapper {
      padding-top: 0px;
      padding-left: 0px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .holzfenstern .textWrapper {
    padding-top: 0px !important; }
    .holzfenstern .textWrapper h1 {
      margin-bottom: 0; }
    .holzfenstern .textWrapper p {
      padding-bottom: 20px;
      line-height: 30px; }
  .wrapperBox .background .bg {
    height: 970px;
    min-height: unset; }
  .bgWrapper .background .bg {
    height: 820px;
    min-height: unset; }
  .certificate {
    margin: 10px;
    padding-top: 0px; }
    .certificate .nav-link {
      padding: 0px !important;
      padding-bottom: 40px !important; }
    .certificate h1 {
      font-size: 48px; }
    .certificate h2 {
      font-size: 14px; }
  .certificate2 {
    margin: 10px; }
    .certificate2 h1 {
      font-size: 42px; }
  .aluminium {
    padding-top: 0px;
    padding-bottom: 40px; }
    .aluminium .textWrapper {
      right: unset;
      text-align: left; }
      .aluminium .textWrapper h1 {
        font-size: 32px; }
        .aluminium .textWrapper h1::after {
          right: unset;
          left: 0; }
      .aluminium .textWrapper p {
        font-size: 18px;
        line-height: 30px; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .bgWrapper .background .bg {
    height: 440px;
    min-height: unset; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .background {
    margin: 20px; }
    .background .bg {
      min-height: 560px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) and (max-width: 767px) and (min-width: 320px) {
  .kunstoff {
    top: 35px; }
    .kunstoff h1 {
      font-size: 32px; }
    .kunstoff p {
      padding-top: 50px;
      font-size: 18px; }
    .kunstoff .textWrapper {
      padding-left: 0px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) {
  .kunstoff {
    top: 50px; }
    .kunstoff h1 {
      font-size: 30px; }
    .kunstoff p {
      font-size: 18px;
      padding-top: 32px; }
    .kunstoff .textWrapper {
      padding-top: 0px;
      padding-left: 0px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) and (max-width: 767px) and (min-width: 320px) {
  .holzfenstern {
    top: 35px; }
    .holzfenstern h1 {
      font-size: 32px; }
    .holzfenstern p {
      padding-top: 50px;
      font-size: 18px; }
    .holzfenstern .textWrapper {
      padding-left: 0px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) {
  .holzfenstern {
    top: 50px; }
    .holzfenstern h1 {
      font-size: 30px; }
    .holzfenstern p {
      font-size: 18px;
      padding-top: 32px; }
    .holzfenstern .textWrapper {
      padding-top: 0px;
      padding-left: 0px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .holzfenstern img {
    max-height: 462px; }
  .holzfenstern .textWrapper p {
    line-height: 30px; }
  .certificate {
    margin: 20px; }
    .certificate h1 {
      font-size: 48px; }
  .certificate2 {
    margin: 20px; }
    .certificate2 h1 {
      font-size: 48px; }
  .aluminium {
    padding-top: 30px;
    padding-bottom: 20px; }
    .aluminium .textWrapper {
      right: unset;
      padding-top: 0px; }
    .aluminium p {
      font-size: 18px;
      line-height: 30px; }
    .aluminium h1 {
      font-size: 48px; } }

.galerija {
  padding-top: 70px;
  padding-bottom: 70px;
  margin-left: 40px;
  margin-right: 40px; }
  .galerija h1 {
    font-size: 64px;
    padding: 0; }
  .galerija h6 {
    margin-left: 3px;
    margin: 0; }
  .galerija .btn-mehrinfo {
    margin-top: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 17px 53px; }
    .galerija .btn-mehrinfo img {
      margin-left: 32px; }
  .galerija .gallery-wrapper .pic {
    margin-top: 30px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .galerija {
    margin-left: 0;
    margin-right: 0;
    padding-top: 20px;
    padding-bottom: 20px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .galerija {
    margin-left: 0;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px; } }
